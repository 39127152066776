import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Typography } from "@mui/material";
import React from "react";

function Error({ error }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        color: "error.main",
      }}
    >
      <ErrorOutlineIcon />
      <Typography variant="body1" sx={{ marginLeft: 2 }}>
        {error}
      </Typography>
    </Box>
  );
}

export default Error;
