import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

const PublicRoute = ({ component: Component }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated() ? <Navigate to="/transcript-processor" /> : <Component />;
};

const PrivateRoute = ({ component: Component }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated() ? <Component /> : <Navigate to="/login" />;
};

export { PrivateRoute, PublicRoute };
