import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import Error from "./Error";
import Logo from "./Logo";
import TranscriptResults from "./TranscriptResults";
import TranscriptUpload from "./TranscriptUpload";

function TranscriptProcessor() {
  const navigate = useNavigate();
  const [step, setStep] = React.useState("upload");
  const [error, setError] = React.useState();
  const [transcript, setTranscript] = React.useState();

  if (step === "upload") {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TranscriptUpload
          handleUpload={async (transcript) => {
            setStep("loading");

            const formData = new FormData();
            formData.append("file", transcript);

            try {
              const token = localStorage.getItem("token");
              const response = await axios.post("/upload", formData, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              });
              console.log(response.data);
              setTranscript(response.data);
              setStep("done");
            } catch (error) {
              console.log(error);
              if (error.response.status === 401) {
                localStorage.removeItem("token");
                window.location.href = "/login";
                navigate("/login", { replace: true });
              } else {
                setError(error.toString());
                setStep("upload");
              }
            }
          }}
        />
        {error && <Box mt="10px"><Error error={error}/></Box>}
      </Box>
    );
  }

  if (step === "loading") {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography variant="body1" sx={{ marginLeft: 2 }}>
            Processing transcript...
          </Typography>
        </Box>
      </Box>
    );
  }

  return <TranscriptResults transcript={transcript} />;
}

export default TranscriptProcessor;
