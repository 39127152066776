import { Box, Card, CardContent } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Login from "./components/Login";
import { PrivateRoute, PublicRoute } from "./components/Routes";
import TranscriptProcessor from "./components/TranscriptProcessor";
import { AuthProvider } from "./providers/AuthProvider";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f0f2f5",
          }}
        >
          <Card>
            <CardContent>
              <Router>
                <Routes>
                  <Route
                    path="/login"
                    element={<PublicRoute component={Login} />}
                  />
                  <Route
                    path="/transcript-processor"
                    element={<PrivateRoute component={TranscriptProcessor} />}
                  />
                  <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
              </Router>
            </CardContent>
          </Card>
        </Box>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
