import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import React, { useMemo, useState } from "react";
import Error from "./Error";

const decimalToPercentage = (decimal) => `${decimal * 100}%`;

const TranscriptResults = ({ transcript }) => {
  const { page_images_base64, data } = transcript;

  const processed_data = useMemo(() => {
    // Find all school keys for table
    const school_keys = new Set();

    for (const school of data.schools) {
      for (const key of Object.keys(school)) {
        school_keys.add(key);
      }
    }

    // Don't include school_name or courses in keys
    school_keys.delete("school_name");
    school_keys.delete("courses");

    const processed_schools = data.schools.map((school) => {
      const grouped_course_data = school.courses.reduce(
        (allCourses, course) => {
          const key = `Year: ${
            !!course.course_year.value ? course.course_year.value : "Unknown"
          }, Grade Level: ${
            !!course.course_grade_level.value
              ? course.course_grade_level.value
              : "Unknown"
          }`;
          if (!allCourses[key]) {
            allCourses[key] = [];
          }
          allCourses[key].push(course);
          return allCourses;
        },
        {}
      );

      // Sort year and grade levels
      const year_and_grade_levels = Object.keys(grouped_course_data).sort(
        (a, b) => a.localeCompare(b)
      );

      // Find all course keys for table
      const course_keys = new Set();

      for (const courses of Object.values(grouped_course_data)) {
        for (const course of courses) {
          for (const key of Object.keys(course)) {
            course_keys.add(key);
          }
        }
      }

      // Don't include year or grade_level in keys
      course_keys.delete("course_year");
      course_keys.delete("course_grade_level");

      return {
        ...school,
        grouped_course_data,
        year_and_grade_levels,
        course_keys: Array.from(course_keys),
      };
    });

    return {
      processed_schools,
      school_keys: Array.from(school_keys),
    };
  }, [data]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % page_images_base64.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + page_images_base64.length) % page_images_base64.length
    );
  };

  const [currentBoundingBox, setCurrentBoundingBox] = useState();

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="column">
        <Box>
          <Box position="relative">
            <img
              src={`data:image/png;base64,${page_images_base64[currentIndex]}`}
              alt={`Page PDF ${currentIndex + 1}`}
              style={{ maxWidth: "100%", maxHeight: "90vh" }}
            />
            {currentBoundingBox && (
              <div
                style={{
                  position: "absolute",
                  outline: "2px solid #c62828",
                  ...currentBoundingBox,
                }}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <Button
              variant="contained"
              onClick={prevImage}
              disabled={page_images_base64.length <= 1}
            >
              Previous
            </Button>
            <Typography variant="body1" sx={{ marginX: 2 }}>
              Page {currentIndex + 1} / {page_images_base64.length}
            </Typography>
            <Button
              variant="contained"
              onClick={nextImage}
              disabled={page_images_base64.length <= 1}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
      {!data ? (
        <Error error="No data found" />
      ) : (
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          style={{ maxWidth: "100%", maxHeight: "90vh", overflowY: "auto" }}
        >
          {processed_data.processed_schools.map((processed_school) => (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography variant="h5">
                  {processed_school.school_name.value}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table sx={{ backgroundColor: "lightgray" }}>
                    <TableHead>
                      <TableRow>
                        {processed_data.school_keys.map((key) => (
                          <TableCell>{key}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {processed_data.school_keys.map((key) => (
                          <TableCell
                            onMouseEnter={() => {
                              const { bounding_box } = processed_school[key];

                              if (!bounding_box) {
                                return;
                              }

                              const { PageNumber, BoundingBox } = bounding_box;

                              const newCurrentIndex = PageNumber - 1;
                              if (currentIndex !== newCurrentIndex) {
                                setCurrentIndex(newCurrentIndex);
                              }

                              setCurrentBoundingBox({
                                width: decimalToPercentage(BoundingBox.Width),
                                height: decimalToPercentage(BoundingBox.Height),
                                left: decimalToPercentage(BoundingBox.Left),
                                top: decimalToPercentage(BoundingBox.Top),
                              });
                            }}
                            onMouseLeave={() => {
                              setCurrentBoundingBox(null);
                            }}
                          >
                            {processed_school[key].value}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {processed_school.year_and_grade_levels.map((grade_level) => (
                  <Box key={grade_level} sx={{ marginTop: 4, marginBottom: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      {grade_level}
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {processed_school.course_keys.map((key) => (
                              <TableCell>{key}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {processed_school.grouped_course_data[
                            grade_level
                          ].map((row, idx) => (
                            <TableRow key={idx}>
                              {processed_school.course_keys.map((key) => (
                                <TableCell
                                  onMouseEnter={() => {
                                    const { bounding_box } = row[key];

                                    if (!bounding_box) {
                                      return;
                                    }

                                    const { PageNumber, BoundingBox } =
                                      bounding_box;

                                    const newCurrentIndex = PageNumber - 1;
                                    if (currentIndex !== newCurrentIndex) {
                                      setCurrentIndex(newCurrentIndex);
                                    }

                                    setCurrentBoundingBox({
                                      width: decimalToPercentage(
                                        BoundingBox.Width
                                      ),
                                      height: decimalToPercentage(
                                        BoundingBox.Height
                                      ),
                                      left: decimalToPercentage(
                                        BoundingBox.Left
                                      ),
                                      top: decimalToPercentage(BoundingBox.Top),
                                    });
                                  }}
                                  onMouseLeave={() => {
                                    setCurrentBoundingBox(null);
                                  }}
                                >
                                  {row[key].value}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default TranscriptResults;
