import React from "react";

function Logo() {
  return (
    <div style={{ background: "#1f1f1f", paddingLeft: "5px", paddingRight: "5px", marginBottom: "10px" }}>
      <img src="/ScarletCS+Logo+SRAR.png" alt="Scarlet CS Logo" />
    </div>
  );
}

export default Logo;
