import { Button, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";

function Login() {
  const [apiKey, setApiKey] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("/login", {
        api_key: apiKey,
      });
      localStorage.setItem("token", response.data.token);
      navigate("/transcript-processor");
    } catch (error) {
      const serverErrorMessage =
        error?.response?.data.detail ?? "Invalid api key";
      setError(serverErrorMessage);
    }
  };

  return (
    <>
      <Logo />
      <TextField
        label="API Key"
        variant="outlined"
        fullWidth
        margin="normal"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        error={!!error}
        helperText={error}
        type="password"
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleLogin}
      >
        Login
      </Button>
    </>
  );
}

export default Login;
