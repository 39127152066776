import { Button } from "@mui/material";
import React from "react";
import Logo from "./Logo";

function TranscriptUpload({ handleUpload }) {
  return (
    <>
      <Logo />
      <input
        type="file"
        accept="application/pdf"
        onChange={async (event) => {
          const selectedFile = event.target.files[0];
          if (selectedFile) {
            handleUpload(selectedFile);
          }
        }}
        style={{ display: "none" }}
        id="file-upload"
      />
      <label htmlFor="file-upload">
        <Button variant="contained" component="span">
          Select PDF File
        </Button>
      </label>
    </>
  );
}

export default TranscriptUpload;
